$background-color: #F3F6FB
$primary-color: #31873F
$primary-color-light: #F3FBF5
$primary-color-dark: #345A43
$text-color: #7E7E7E
$white: #FFF
$red: #EA4949
$font: 'Verdana', sans-serif

body
  background: $background-color
  margin: 0
  padding: 0
  font-family: 'Verdana', sans-serif

.dashboard
  display: flex
  flex-direction: row

.view-title
  color: $text-color
  font-size: 2.1rem
  font-weight: bold
  margin-top: 70px

.view
  width: 100%
  max-height: 100vh
  padding-left: 100px
  padding-right: 100px
  padding-top: 0 !important
  overflow: auto
  flex: 1 1 auto

.client-container
  display: flex
  flex-direction: row
  flex-wrap: wrap
  position: absolute
  top: 180px

  .client
    width: 200px
    height: 150px
    background-color: white
    border-radius: 10px
    border: 3px solid white
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2)
    margin: 0 20px 20px 0px
    cursor: pointer
    transition-duration: 200ms
    display: flex
    justify-content: center
    align-items: center

    &:hover
      transform: scale(1.05)
      transition-duration: 200ms

  .client-new
    @extend .client
    box-shadow: none
    background: none
    border: 3px solid #DDD
    color: #DDD
    font-size: 2.5em
    user-select: none

a
  text-decoration: none
  color: black

.ReactModal__Content
  max-width: 280px !important
  min-width: 280px !important
  justify-content: center
  align-items: center
  padding: 20px 20px 15px 20px !important
  border-radius: 10px !important
  // border: 1px solid $primary-color !important
  // box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.2) !important
  border: 1px solid gray

.modal-title
  font-size: 1.3em
  margin: 0 0 20px 0
  color: $text-color

.modal-form-field
  display: flex
  flex-direction: column
  margin: 10px 0

  label
    font-weight: 600
    color: $text-color
    margin-bottom: 3px
    font-size: 0.9em

  .modal-textinput
    border-radius: 5px
    padding: 5px
    border: 1px solid #AAA

.modal-btn-add
  width: 100%
  border: 0
  border-radius: 5px
  background-color: $primary-color
  color: white
  padding: 6px
  cursor: pointer
  margin-top: 5px