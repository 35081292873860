@import default
  
.setting
  background: white
  padding: 15px 20px
  border-radius: 5px
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2)
  margin: 15px 0

  a
    color: $primary-color
    text-decoration: underline

  h3
    margin: 0 0 10px 0
    color: $text-color

  p
    margin: 0
    color: $text-color

  input
    font: $font
    border: 1px solid #CCC
    border-radius: 5px
    padding: 5px

.btn-delete
  background: none
  float: right
  border: none
  padding: 10px
  color: $red
  border: 2px solid $red
  border-radius: 5px
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2)
  font-weight: bold
  cursor: pointer
  transition-duration: 200ms

  &:hover
    color: white
    background: $red
    transition-duration: 200ms