
@import default

.sidepanel-container
  display: flex
  height: 100vh
  z-index: 100

.sidepanel
  background-color: white
  min-width: 400px
  height: 100vh
  display: flex
  flex-direction: column
  align-self: flex-start

  .sidepanel-top
    display: flex
    flex-direction: column
    align-items: center
    padding-top: 20px

    .sidepanel-greet
      color: $primary-color
      font-size: 1.7rem
      margin: 50px 0 0 0

    .img-profile
      width: 80px
      max-height: 80px
      display: flex
      justify-content: center
      align-items: center

      img
        max-width: 180px
        border-radius: 100px

  .sidepanel-bottom
    padding-left: 50px

    nav > ul
      list-style-type: none

    ul li
      padding: 5px 0 5px 0
      margin: 10px 0 10px 0
      color: $text-color
      font-size: 1.1rem
      cursor: pointer

    .link, a
      text-decoration: none
      
    .nav-inactive
      display: flex
      flex-direction: row
      justify-content: space-between
      align-items: center

      &::after
        content: ""
        height: 30px  

    .nav-active
      display: flex
      flex-direction: row
      justify-content: space-between
      align-items: center
      color: $primary-color-dark
      
      &::after
        content: ""
        height: 30px
        border-right: 6px solid $primary-color-dark
        border-radius: 15px 0 0 15px

    .nav-top
      padding-top: 30px
      text-decoration: none

    .nav-bottom
      position: absolute
      bottom: 0
      padding-bottom: 30px

    .logo-small
      max-width: 50px
      padding-top: 20px