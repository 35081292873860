@import default

.left
  width: 50%
  display: flex
  justify-content: center
  align-items: center

  .login-image
    max-height: 400px
    z-index: 10
    position: relative
    right: 5%

  .diagonal
    position: fixed
    top: 0
    left: -25%
    background-color: white
    width: 70%
    height: 100vh
    transform: skewX(-25deg)
    z-index: 0

.right
  width: 50%
  display: flex
  justify-content: center
  align-items: center
  font-family: 'Roboto', sans-serif

  .login-box
    background-color: white
    box-shadow: 0 3px 3px 0px rgba(0, 0, 0, 0.3)
    border-radius: 20px
    width: 350px
    padding: 80px
    display: flex
    flex-direction: column

    .login-title
      color: $primary-color
      margin: 0

    .login-description
      color: $text-color
      font-size: 0.9em
      margin-bottom: 50px

    form
      display: flex
      flex-direction: column

      input
        background-color: $primary-color-light
        border: 1px solid $primary-color-dark
        border-radius: 20px
        text-align: center
        padding: 10px
        margin: 2px 0

    .login-forgot-password
      @extend .login-description
      margin: 25px 0
      text-align: left

    .btn-login
      background-color: $primary-color-dark
      border-radius: 20px
      text-align: center
      padding: 10px
      color: white
      cursor: pointer
      border: none

.page-login
  display: flex
  flex-direction: row
  width: 100vw
  height: 100vh