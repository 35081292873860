@import default

.data-list
  margin-bottom: 100px

.list-item
  background: white
  width: 98%
  height: 50px
  margin: 9px 0
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2)
  border-radius: 5px
  display: flex
  flex-direction: row
  justify-content: space-between
  align-items: space
  padding-left: 20px
  padding-right: 15px
  font-family: $font
  transition-duration: 200ms

  &:hover
    transition-duration: 200ms
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.3)

  .list-item-category
    color: $text-color

  .list-item-delete
    background: none
    border: none
    color: red
    padding: 5px
    cursor: pointer
    font-weight: bold
    position: relative
    top: 1px

.btn-add
  border: none
  background: $primary-color
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2)
  color: white
  font-weight: 600
  padding: 10px 15px
  border-radius: 5px
  cursor: pointer
  transition-duration: 200ms
  margin-bottom: 5px

  &:hover
    transition-duration: 200ms
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.3)

.branch-list
  display: flex
  flex-wrap: wrap
  flex-direction: row
  margin-bottom: 100px

.branch-item
  background-color: white
  padding: 15px 20px
  border-radius: 5px
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2)
  margin: 15px 15px 15px 0
  width: 400px
  box-sizing: border-box

  h3
    margin: 0 0 10px 0
    color: $text-color

  textarea
    min-width: 100%
    max-width: 100%
    box-sizing: border-box
    height: 150px
    resize: none
    font: $font
    font-size: 1.3em
    border: 1px solid #CCC
    border-radius: 5px
    padding: 5px

  .branch-item-result
    margin-top: 10px

    #result
      margin: 0

    label
      margin-left: 8px

.dropdown
  margin-bottom: 10px
  padding: 3px

.btn-save
  // background-color: $primary-color
  color: white
  padding: 10px
  border: none
  border-radius: 0px
  position: fixed
  bottom: 0px 
  right: 0px
  font-size: 1.1em
  width: 100%
  text-align: right
  z-index: 1